<!-- =========================================================================================
    File Name: byReference.vue
    Description: byReference Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <vx-card
      :title="$t(resources.Get.i18n) || resources.Get.name"
    >
      <form class="vx-row" data-vv-scope="form-1">
        <div class="vx-col md:w-1/5 w-full mb-2">
          <vs-input
            name="bookingCode"
            :label="$t(resources.BookingCode.i18n) || resources.BookingCode.name"
            v-model="bookingCode"
            v-validate="'required|alpha_dash'"
            class="w-full"
            icon-pack="feather"
            icon="icon-hash"
            maxlength="20"
          />
          <span class="text-danger text-sm">{{ errors.first("form-1.bookingCode") }}</span>
        </div>
        <div class="vx-col md:w-1/5 w-full mb-2">
          <label class="vs-input--label">{{
            $t(resources.Supplier.i18n) || resources.Supplier.name
          }}</label>
          <v-select
            name="supplier"
            label="name"
            :placeholder="$t(resources.Select.i18n) || resources.Select.name"
            v-model="supplier"
            v-validate="'required'"
            :clearable="false"
            :options="gdsOption"
            :reduce="country => country.id"
          ></v-select>
          <span class="text-danger text-sm">{{ errors.first("form-1.supplier") }}</span>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <label class="vs-input--label">{{
            $t(resources.CountryOperation.i18n) ||
              resources.CountryOperation.name
          }}</label>
          <v-select
            name="country"
            :placeholder="$t(resources.Select.i18n) || resources.Select.name"
            v-model="country"
            v-validate="'required'"
            :clearable="true"
            :options="countryOption"
            :reduce="item => item.countryCode"
            label="countryName"
          ></v-select>
          <span class="text-danger text-sm">{{ errors.first("form-1.country") }}</span>
        </div>
        <div class="vx-col mt-6">
          <vs-button color="primary" type="filled" @click="getByReference">
            {{ $t(resources.Search.i18n) || resources.Search.name }}
          </vs-button>
        </div>
      </form>
    </vx-card>

    <form v-if="byReference != null" data-vv-scope="form-2">
      <vx-card
        :title="$t(resources.Detail.i18n) || resources.Detail.name"
        class="mt-4"
      >
        <div class="vx-row">
          <div class="vx-col md:w-1/4 w-full mb-2">
            <vs-input
              :label="$t(resources.DistributorID.i18n) || resources.DistributorID.name"
              v-model="ita"
              class="w-full"
              readonly
            />
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <label class="vs-input--label">{{
              $t(resources.Customer.i18n) || resources.Customer.name
            }}</label>
            <v-select
              name="contactName"
              :placeholder="$t(resources.Select.i18n) || resources.Select.name"
              v-model="contact"
              v-validate="'required'"
              :clearable="false"
              :options="contactOption"
              :reduce="item => item.id"
              label="name"
            />
            <span class="text-danger text-sm">{{ errors.first("form-2.contactName") }}</span>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <vs-input
              :label="$t(resources.NetValue.i18n) || resources.NetValue.name"
              v-model="netValue"
              type="number"
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              v-on:change="sumValues"
            />
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <vs-input
              :label="$t(resources.TaxesValue.i18n) || resources.TaxesValue.name"
              v-model="totalTaxes"
              type="number"
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              v-on:change="sumValues"
            />
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <vs-input
              :label="$t(resources.FeeValue.i18n) || resources.FeeValue.name"
              v-model="feeValue"
              type="number"
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              v-on:change="sumValues"
              maxlength="15"
            />
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <vs-input
              :label="$t(resources.MerchantValue.i18n) || resources.MerchantValue.name"
              v-model="merchantValue"
              type="number"
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              v-on:change="sumValues"
              maxlength="15"
            />
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <vs-input
              :label="$t(resources.AgentCommissionValue.i18n) || resources.AgentCommissionValue.name"
              v-model="agentCommission"
              type="number"
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              v-on:change="sumValues"
              readonly
            />
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <vs-input
              :label="$t(resources.BookingValue.i18n) || resources.BookingValue.name"
              v-model="total"
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              readonly
            />
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <vs-input
              :label="$t(resources.FeeCustomized.i18n) || resources.FeeCustomized.name"
              v-model="feeCustomized"
              type="number"
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              v-on:change="sumValues"
            />
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <vs-input
              :label="$t(resources.TotalValue.i18n) || resources.TotalValue.name"
              :value="totalDisplay"
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              readonly
            />
          </div>
        </div>
      </vx-card>

      <vx-card
        :title="$t(resources.ContactFormTitle.i18n) || resources.ContactFormTitle.name"
        class="mt-4"
      >
        <div class="vx-row">
          <div class="vx-col md:w-1/4 w-full mb-2">
            <vs-input
              name="emergencyName"
              :label="$t(resources.ContactName.i18n) || resources.ContactName.name"
              v-model="emergencyName"
              v-validate="'required|min:3|alpha_spaces'"
              class="w-full"
              icon-pack="feather"
              icon="icon-user"
              maxlength="100"
            />
            <span class="text-danger text-sm">{{ errors.first("form-2.emergencyName") }}</span>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <vs-input
              name="emailVoucher"
              :label="$t(resources.Email.i18n) || resources.Email.name"
              v-model="emailVoucher"
              v-validate="'required|email'"
              class="w-full"
              icon-pack="feather"
              icon="icon-mail"
              maxlength="150"
            />
            <span class="text-danger text-sm">{{ errors.first("form-2.emailVoucher") }}</span>
          </div>
          <div class="vx-col md:w-1/6 w-full mb-2">
            <label class="vs-input--label">{{
              $t(resources.TelephoneType.i18n) || resources.TelephoneType.name
            }}</label>
            <v-select
              name="phoneType"
              :placeholder="$t(resources.Select.i18n) || resources.Select.name"
              v-model="emergencyPhoneType"
              v-validate="'required'"
              :clearable="false"
              :options="phoneTypes"
              :reduce="item => item.id"
              label="name"
            />
            <span class="text-danger text-sm">{{ errors.first("form-2.phoneType") }}</span>
          </div>
          <div class="vx-col md:w-1/6 w-full mb-2">
            <label class="vs-input--label">{{
              $t(resources.Indicative.i18n) || resources.Indicative.name
            }}</label>
            <v-select
              name="emergencyCountryCode"
              :placeholder="$t(resources.Select.i18n) || resources.Select.name"
              v-model="emergencyCountryCode"
              v-validate="'required'"
              :clearable="false"
              :options="countryList"
              :reduce="item => item.id"
              label="name"
            />
            <span class="text-danger text-sm">{{ errors.first("form-2.emergencyCountryCode") }}</span>
          </div>
          <div class="vx-col md:w-1/6 w-full mb-2">
            <vs-input
              name="emergencyPhone"
              :label="$t(resources.Number.i18n) || resources.Number.name"
              v-model="emergencyPhone"
              v-validate="'required|min:8'"
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              maxlength="20"
            />
            <span class="text-danger text-sm">{{ errors.first("form-2.emergencyPhone") }}</span>
          </div>
        </div>
      </vx-card>

      <vx-card class="mt-4">
        <vs-tabs>
          <vs-tab
            :label="$t(resources.Itinerary.i18n) || resources.Itinerary.name"
          >
            <div
              :key="index"
              v-for="(item, index) in byReference.itineraries"
            >
              <vs-divider
                ><strong class="text-primary">
                  {{
                    $t(resources.Itinerary.i18n) + " " + (index + 1) ||
                      resources.Itinerary.name + " " + (index + 1)
                  }}
                </strong>
              </vs-divider>
              <div class="vx-row">
                <div class="vx-col md:w-1/4 w-full mb-2">
                  <vs-input
                    :label="$t(resources.Origin.i18n) || resources.Origin.name"
                    v-model="item.origin"
                    class="w-full"
                    readonly
                  />
                </div>
                <div class="vx-col md:w-1/4 w-full mb-2">
                  <vs-input
                    :label="$t(resources.Destination.i18n) || resources.Destination.name"
                    v-model="item.destination"
                    class="w-full"
                    readonly
                  />
                </div>
                <div class="vx-col md:w-1/4 w-full mb-2">
                  <vs-input
                    :label="$t(resources.StartDate.i18n) || resources.StartDate.name"
                    v-model="item.departureDate"
                    class="w-full"
                    readonly
                  />
                </div>
                <div class="vx-col md:w-1/4 w-full mb-2">
                  <vs-input
                    :label="$t(resources.EndDate.i18n) || resources.EndDate.name"
                    v-model="item.arrivalDate"
                    class="w-full"
                    readonly
                  />
                </div>
                <div class="vx-col md:w-1/4 w-full mb-2">
                  <vs-input
                    :label="$t(resources.Class.i18n) || resources.Class.name"
                    v-model="item.class"
                    class="w-full"
                    readonly
                  />
                </div>
                <div class="vx-col md:w-1/4 w-full mb-2">
                  <vs-input
                    :label="$t(resources.Airline.i18n) || resources.Airline.name"
                    v-model="item.airlineCode"
                    class="w-full"
                    readonly
                  />
                </div>
                <div class="vx-col md:w-1/4 w-full mb-2">
                  <vs-input
                    :label="$t(resources.FlightNumber.i18n) || resources.FlightNumber.name"
                    v-model="item.flightNumber"
                    class="w-full"
                    readonly
                  />
                </div>
              </div>
            </div>
          </vs-tab>
          <vs-tab
            :label="$t(resources.Passengers.i18n) || resources.Passengers.name"
          >
            <div
              :key="index"
              v-for="(item, index) in byReference.passengers"
            >
              <vs-divider
                ><strong class="text-primary">
                  {{
                    $t(resources.Passenger.i18n) + " " + (index + 1) ||
                      resources.Passenger.name + " " + (index + 1)
                  }}</strong
                >
              </vs-divider>
              <div class="vx-row">
                <div class="vx-col md:w-1/4 w-full mb-2">
                  <vs-input
                    :label="$t(resources.Name.i18n) || resources.Name.name"
                    v-model="item.name"
                    class="w-full"
                    readonly
                  />
                </div>
                <div class="vx-col md:w-1/4 w-full mb-2">
                  <vs-input
                    :label="$t(resources.LastName.i18n) || resources.LastName.name"
                    v-model="item.lastName"
                    class="w-full"
                    readonly
                  />
                </div>
                <div class="vx-col md:w-1/4 w-full mb-2">
                  <vs-input
                    :name="'passengerMail' + (index + 1)"
                    :label="$t(resources.Email.i18n) || resources.Email.name"
                    v-model="item.email"
                    v-validate="'required|email'"
                    class="w-full"
                    maxlength="150"
                  />
                  <span class="text-danger text-sm">{{ errors.first("form-2.passengerMail" + (index + 1)) }}</span>
                </div>
                <div class="vx-col md:w-1/4 w-full mb-2">
                  <vs-input
                    :name="'passengerPhone' + (index + 1)"
                    :label="$t(resources.Phone.i18n) || resources.Phone.name"
                    v-model="item.phone"
                    v-validate="'required|min:8'"
                    class="w-full"
                    icon-pack="feather"
                    icon="icon-phone"
                    maxlength="20"
                  />
                  <span class="text-danger text-sm">{{ errors.first("form-2.passengerPhone" + (index + 1)) }}</span>
                </div>
                <div class="vx-col md:w-1/4 w-full mb-2">
                  <label class="vs-input--label">{{
                    $t(resources.PassengerType.i18n) || resources.PassengerType.name
                  }}</label>
                  <v-select
                    :name="'passengerType' + (index + 1)"
                    v-model="item.typeId"
                    v-validate="'required'"
                    :clearable="false"
                    :options="passengerType"
                    :reduce="x => x.id"
                    label="name"
                  />
                  <span class="text-danger text-sm">{{ errors.first("form-2.passengerType" + (index + 1)) }}</span>
                </div>
                <div class="vx-col md:w-1/4 w-full mb-2">
                  <label class="vs-input--label">{{
                    $t(resources.DocumentType.i18n) || resources.DocumentType.name
                  }}</label>
                  <v-select
                    :name="'passengerDocumentType' + (index + 1)"
                    :placeholder="$t(resources.Select.i18n) || resources.Select.name"
                    v-model="item.documentTypeId"
                    v-validate="'required'"
                    :clearable="false"
                    :options="documentTypeOption"
                    :reduce="x => x.id"
                    label="name"
                  />
                  <span class="text-danger text-sm">{{ errors.first("form-2.passengerDocumentType" + (index + 1)) }}</span>
                </div>
                <div class="vx-col md:w-1/4 w-full mb-2">
                  <vs-input
                    :name="'passengerDocumentNumber' + (index + 1)"
                    :label="$t(resources.DocumentNumber.i18n) || resources.DocumentNumber.name"
                    v-model="item.document"
                    v-validate="'required|alpha_num'"
                    class="w-full"
                    icon-pack="feather"
                    icon="icon-hash"
                    maxlength="25"
                  />
                  <span class="text-danger text-sm">{{ errors.first("form-2.passengerDocumentNumber" + (index + 1)) }}</span>
                </div>
                <div class="vx-col md:w-1/4 w-full mb-2">
                  <label class="vs-input--label">{{
                    $t(resources.BirthDate.i18n) || resources.BirthDate.name
                  }}</label>
                  <datepicker
                    :name="'passengerBirthDate' + (index + 1)"
                    v-model="item.birthDate"
                    v-validate="'required'"
                    format="d MMMM yyyy"
                    :language="languages[language]"
                  ></datepicker>
                  <span class="text-danger text-sm">{{ errors.first("form-2.passengerBirthDate" + (index + 1)) }}</span>
                </div>
                <div class="vx-col md:w-1/4 w-full mb-2">
                  <label class="vs-input--label">{{
                    $t(resources.ExpirationDatePassport.i18n) ||
                      resources.ExpirationDatePassport.name
                  }}</label>
                  <datepicker
                    v-model="item.expirationDate"
                    format="d MMMM yyyy"
                    :language="languages[language]"
                  ></datepicker>
                </div>
                <div class="vx-col md:w-1/4 w-full mb-2"
                  v-if="country == 'USA'">
                  <label class="vs-input--label">{{
                    $t(resources.Country.i18n) || resources.Country.name
                  }}</label>
                  <v-select
                    :name="'passengerNationality' + (index + 1)"
                    :placeholder="$t(resources.Select.i18n) || resources.Select.name"
                    v-model="item.nationality"
                    v-validate="'required'"
                    :clearable="false"
                    :options="countryPassenger"
                    :reduce="x => x.id"
                    label="name"
                  />
                  <span class="text-danger text-sm">{{ errors.first("form-2.passengerNationality" + (index + 1)) }}</span>
                </div>
              </div>
            </div>
          </vs-tab>
        </vs-tabs>
      </vx-card>

      <div class="vx-col mb-2 mt-5" style="text-align: right">
        <vs-button color="primary" type="filled" @click="generateBooking">
          {{ $t(resources.Save.i18n) || resources.Save.name }}
        </vs-button>
      </div>
    </form>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import axios from "axios";
import vSelect from "vue-select";
import * as lang from "vuejs-datepicker/src/locale";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  components: {
    vSelect,
    Datepicker
  },
  data() {
    return {
      alerts: alerts,
      language: this.$i18n.locale,
      languages: lang,
      bookingCode: "",
      supplier: "",
      country: "",
      product: "2",
      resources: resources,
      byReference: null,
      itineraries: [],
      passengers: [],
      gdsOption: [],
      countryOption: [],
      productOption: [],
      ita: "",
      contact: "",
      contactOption: [],
      countryCode: "",
      reservationType: "",
      reservationTypeOption: [],
      totalTaxes: "",
      netValue: "",
      feeValue: "",
      merchantValue: "",
      agentCommission: 0,
      feeCustomized: "",
      total: "",
      totalDisplay: "",
      documentTypeId: "",
      documentTypeOption: [],
      documentNumber: "",
      objItinerary: {},
      objPassenger: {},
      bookingFlightItinerary: [],
      bookingFlightPassenger: [],
      itaEmail: "",
      documentPassenger: [],
      countryList: [],
      countryPassenger: [],
      emergencyName: "",
      emailVoucher: "",
      phoneTypes: [
        { id: 1, name: "Local" },
        { id: 2, name: "Mobile" }
      ],
      passengerType: [
        { id: 1, name: "Adulto" },
        { id: 4, name: "Bebé con asiento" },
        { id: 5, name: "Bebé en brazos" },
        { id: 6, name: "Bebé acompañado" },
        { id: 7, name: "Niño acompañado" },
        { id: 8, name: "Niño no acompañado" }
      ],
      emergencyPhoneType: "",
      emergencyCountryCode: "",
      emergencyPhone: ""
    };
  },
  async created() {
    await this.getCountries();
    await this.getGds();
    await this.getProducts();
    await this.getIdentificationType();
    await this.getCountry();
  },
  methods: {
    sumValues() {
      this.total =
        parseFloat(this.netValue) +
        parseFloat(this.totalTaxes) +
        parseFloat(this.feeValue) +
        parseFloat(this.merchantValue) +
        parseFloat(this.agentCommission);
      
      this.totalDisplay =
        parseFloat(this.total) +
        parseFloat(this.feeCustomized);
    },

    async getGds() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Supplier`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.gdsOption = [];
          } else {
            this.gdsOption = result.data.filter(
              x => x.name == "Sabre" || x.name == "Kiu"
            );
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },
    async getCountries() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }CountryOperation/GetPccCountry/${this.$i18n.locale.toUpperCase()}/1`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.countryOption = [];
          } else {
            this.countryOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },
    async getProducts() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Product/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.productOption = [];
          } else {
            this.productOption = result.data.filter(x => x.code == "AIR");
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },
    async getCountry() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Country/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.countryList = result.data.map(x => ({
              id: x.iso3,
              name: `${x.name} (${x.indicative})`
            }));
            this.countryPassenger = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getByReference() {
      this.byReference = null;
      this.$validator.validateAll("form-1").then(async result => {
        if (result) {
          this.$vs.loading();

          await axios({
            method: "POST",
            url: `${process.env.VUE_APP_APIVT}Booking/ByReference`,
            data: {
              supplierId: this.supplier,
              productId: this.product,
              bookingReference: this.bookingCode,
              countryCode: this.country,
              language: this.$i18n.locale.toUpperCase()
            },
            headers: {
              "content-type": "application/json",
              Authorization: `bearer ${localStorage.getItem("tokenVT")}`
            }
          }).then(
            async result => {
              if (result.data) {
                this.byReference = result.data.bookingDetail[0];
                this.ita = result.data.itaCode;
                this.itaEmail = result.data.itaEmail;
                this.emergencyName = result.data.itaName;
                this.emailVoucher = result.data.itaEmail;
                this.emergencyPhoneType = 2;
                await this.getCustomer();
                this.countryCode = this.country;
                this.netValue = result.data.bookingDetail[0].valueNeto;
                this.totalTaxes = result.data.bookingDetail[0].tax;
                this.feeValue = result.data.bookingDetail[0].fee;
                this.agentCommission = result.data.bookingDetail[0].agentCommission;
                this.merchantValue = result.data.bookingDetail[0].merchant;
                this.feeCustomized = result.data.bookingDetail[0].feeCustomized;
                this.total = result.data.bookingDetail[0].price;
                this.totalDisplay = this.total + this.feeCustomized;
              } else {
                this.$vs.notify({
                  title: this.$i18n.t("Alert"),
                  text: this.$i18n.t("MsgNotFoundInfo"),
                  color: "warning",
                  iconPack: "feather",
                  position: "top-right",
                  icon: "icon-x-circle"
                });
              }
            },
            error => {
              this.$vs.notify({
                title: this.$i18n.t("Error"),
                text: this.$i18n.t("MsgError") + "<br/>" + error.message,
                color: "danger",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
            }
          );
          this.$vs.loading.close();
        } else {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgRequiredField"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      });
    },

    async getIdentificationType() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }IdentificationType/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.documentTypeOption = [];
          } else {
            this.documentTypeOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async createBooking() {
      let contactInfo = JSON.parse(localStorage.getItem("userInfo"));
      let bookingItineraryData = this.bookingFlightItinerary;
      let bookingPassengerData = this.bookingFlightPassenger;

      bookingItineraryData.forEach(iti => {
        if(iti.segmentLeg === 0 ){
          delete iti.segmentLeg
        }
      })


      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Booking`,
        data: {
          bookingId: 0,
          contactId: this.contact,
          itaCode: this.ita,
          countryCode: this.country,
          emailVoucher: this.emailVoucher,
          confirmEmailVoucher: this.emailVoucher,
          emergencyName: this.emergencyName,
          emergencyCountryCode: this.emergencyCountryCode,
          emergencyPhoneType: this.emergencyPhoneType,
          emergencyPhone: this.emergencyPhone,
          language: this.$i18n.locale.toUpperCase(),
          applicationId: 1,
          createdBy: contactInfo.email,
          bookingDetail: [
            {
              productId: this.byReference.productId,
              supplierId: this.supplier,
              ProductBookingCode: this.byReference.productoBookingReference,
              bookingSourceId: 21,
              createdBy: contactInfo.email,
              reserved: true,
              currencyCode: this.byReference.currency,
              detail: {
                bookingFlightItinerary: bookingItineraryData,
                bookingFlightPassenger: bookingPassengerData
              },
              value: this.netValue,
              fee: this.feeValue,
              tax: this.totalTaxes,
              merchant: this.merchantValue,
              agentCommission: this.agentCommission,
              feeCustomized: this.feeCustomized,
              othervalue: 0,
              total: this.total
            }
          ]
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.status != 200) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$router.push({
              name: "bookingDetail",
              params: {
                bookingId: result.data.id
              }
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.byReference = null;
      this.bookingFlightItinerary = [];
    },
    async generateBooking() {
      this.$validator.validateAll("form-2").then(async result => {
        if (result) {
          this.$vs.loading();
          this.objItinerary = {};
          this.objPassenger = {};

          const _itineraries = this.byReference.itineraries.map(x => ({
            origin: x.origin,
            destination: x.destination,
            departureDate: x.departureDate,
            arrivalDate: x.arrivalDate,
            airlineCode: x.airlineCode,
            flightNumber: x.flightNumber,
            class: x.class,
            segmentLeg: x.segmentLeg
          }));

          this.bookingFlightItinerary = _itineraries;

          const _passengers = this.byReference.passengers.map(x => ({
            passengerTypeId: x.typeId,
            name: x.name,
            lastName: x.lastName,
            documentType: x.documentTypeId,
            documentNumber: x.document,
            expirationDate: x.expirationDate,
            birthDay: x.birthDate != null ? moment(x.birthDate).format("DD") : "1900",
            birthMonth: x.birthDate != null ? moment(x.birthDate).format("MM") : "1",
            birthYear: x.birthDate != null ? moment(x.birthDate).format("YYYY") : "1",
            email: x.email,
            phone: x.phone,
            nationality: x.nationality
          }));

          this.bookingFlightPassenger = _passengers;
          await this.createBooking();
          this.$vs.loading.close();
        } else if (this.feeCustomized > (this.total * 0.15)){
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: `El fee personalizado no puede ser mayor a $${(this.total * 0.15).toFixed(2).toLocaleString('es-MX')}, equivalente al 15% del valor de la reserva.`,
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
        else {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgRequiredField"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      });
    },

    async getCustomer() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Contact/Customer/${this.$i18n.locale.toUpperCase()}/${this.ita}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.contactOption = [];
          } else {
            this.contactOption = result.data.map(x => ({
              id: x.id,
              name: `${x.name} ${x.lastName}`
            }));
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    }
  }
};
</script>
<style>
  .con-vs-tabs, .con-vs-tabs .con-slot-tabs {
    overflow: unset !important;
  }
</style>
