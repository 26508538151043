var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        {
          attrs: {
            title: _vm.$t(_vm.resources.Get.i18n) || _vm.resources.Get.name
          }
        },
        [
          _c(
            "form",
            { staticClass: "vx-row", attrs: { "data-vv-scope": "form-1" } },
            [
              _c(
                "div",
                { staticClass: "vx-col md:w-1/5 w-full mb-2" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|alpha_dash",
                        expression: "'required|alpha_dash'"
                      }
                    ],
                    staticClass: "w-full",
                    attrs: {
                      name: "bookingCode",
                      label:
                        _vm.$t(_vm.resources.BookingCode.i18n) ||
                        _vm.resources.BookingCode.name,
                      "icon-pack": "feather",
                      icon: "icon-hash",
                      maxlength: "20"
                    },
                    model: {
                      value: _vm.bookingCode,
                      callback: function($$v) {
                        _vm.bookingCode = $$v
                      },
                      expression: "bookingCode"
                    }
                  }),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("form-1.bookingCode")))
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/5 w-full mb-2" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Supplier.i18n) ||
                          _vm.resources.Supplier.name
                      )
                    )
                  ]),
                  _c("v-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    attrs: {
                      name: "supplier",
                      label: "name",
                      placeholder:
                        _vm.$t(_vm.resources.Select.i18n) ||
                        _vm.resources.Select.name,
                      clearable: false,
                      options: _vm.gdsOption,
                      reduce: function(country) {
                        return country.id
                      }
                    },
                    model: {
                      value: _vm.supplier,
                      callback: function($$v) {
                        _vm.supplier = $$v
                      },
                      expression: "supplier"
                    }
                  }),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("form-1.supplier")))
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.CountryOperation.i18n) ||
                          _vm.resources.CountryOperation.name
                      )
                    )
                  ]),
                  _c("v-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    attrs: {
                      name: "country",
                      placeholder:
                        _vm.$t(_vm.resources.Select.i18n) ||
                        _vm.resources.Select.name,
                      clearable: true,
                      options: _vm.countryOption,
                      reduce: function(item) {
                        return item.countryCode
                      },
                      label: "countryName"
                    },
                    model: {
                      value: _vm.country,
                      callback: function($$v) {
                        _vm.country = $$v
                      },
                      expression: "country"
                    }
                  }),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("form-1.country")))
                  ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col mt-6" },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: { color: "primary", type: "filled" },
                      on: { click: _vm.getByReference }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(_vm.resources.Search.i18n) ||
                              _vm.resources.Search.name
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ]
      ),
      _vm.byReference != null
        ? _c(
            "form",
            { attrs: { "data-vv-scope": "form-2" } },
            [
              _c(
                "vx-card",
                {
                  staticClass: "mt-4",
                  attrs: {
                    title:
                      _vm.$t(_vm.resources.Detail.i18n) ||
                      _vm.resources.Detail.name
                  }
                },
                [
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            label:
                              _vm.$t(_vm.resources.DistributorID.i18n) ||
                              _vm.resources.DistributorID.name,
                            readonly: ""
                          },
                          model: {
                            value: _vm.ita,
                            callback: function($$v) {
                              _vm.ita = $$v
                            },
                            expression: "ita"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                      [
                        _c("label", { staticClass: "vs-input--label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.Customer.i18n) ||
                                _vm.resources.Customer.name
                            )
                          )
                        ]),
                        _c("v-select", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          attrs: {
                            name: "contactName",
                            placeholder:
                              _vm.$t(_vm.resources.Select.i18n) ||
                              _vm.resources.Select.name,
                            clearable: false,
                            options: _vm.contactOption,
                            reduce: function(item) {
                              return item.id
                            },
                            label: "name"
                          },
                          model: {
                            value: _vm.contact,
                            callback: function($$v) {
                              _vm.contact = $$v
                            },
                            expression: "contact"
                          }
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(_vm._s(_vm.errors.first("form-2.contactName")))
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            label:
                              _vm.$t(_vm.resources.NetValue.i18n) ||
                              _vm.resources.NetValue.name,
                            type: "number",
                            "icon-pack": "feather",
                            icon: "icon-dollar-sign"
                          },
                          on: { change: _vm.sumValues },
                          model: {
                            value: _vm.netValue,
                            callback: function($$v) {
                              _vm.netValue = $$v
                            },
                            expression: "netValue"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            label:
                              _vm.$t(_vm.resources.TaxesValue.i18n) ||
                              _vm.resources.TaxesValue.name,
                            type: "number",
                            "icon-pack": "feather",
                            icon: "icon-dollar-sign"
                          },
                          on: { change: _vm.sumValues },
                          model: {
                            value: _vm.totalTaxes,
                            callback: function($$v) {
                              _vm.totalTaxes = $$v
                            },
                            expression: "totalTaxes"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            label:
                              _vm.$t(_vm.resources.FeeValue.i18n) ||
                              _vm.resources.FeeValue.name,
                            type: "number",
                            "icon-pack": "feather",
                            icon: "icon-dollar-sign",
                            maxlength: "15"
                          },
                          on: { change: _vm.sumValues },
                          model: {
                            value: _vm.feeValue,
                            callback: function($$v) {
                              _vm.feeValue = $$v
                            },
                            expression: "feeValue"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            label:
                              _vm.$t(_vm.resources.MerchantValue.i18n) ||
                              _vm.resources.MerchantValue.name,
                            type: "number",
                            "icon-pack": "feather",
                            icon: "icon-dollar-sign",
                            maxlength: "15"
                          },
                          on: { change: _vm.sumValues },
                          model: {
                            value: _vm.merchantValue,
                            callback: function($$v) {
                              _vm.merchantValue = $$v
                            },
                            expression: "merchantValue"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            label:
                              _vm.$t(_vm.resources.AgentCommissionValue.i18n) ||
                              _vm.resources.AgentCommissionValue.name,
                            type: "number",
                            "icon-pack": "feather",
                            icon: "icon-dollar-sign",
                            readonly: ""
                          },
                          on: { change: _vm.sumValues },
                          model: {
                            value: _vm.agentCommission,
                            callback: function($$v) {
                              _vm.agentCommission = $$v
                            },
                            expression: "agentCommission"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            label:
                              _vm.$t(_vm.resources.BookingValue.i18n) ||
                              _vm.resources.BookingValue.name,
                            "icon-pack": "feather",
                            icon: "icon-dollar-sign",
                            readonly: ""
                          },
                          model: {
                            value: _vm.total,
                            callback: function($$v) {
                              _vm.total = $$v
                            },
                            expression: "total"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            label:
                              _vm.$t(_vm.resources.FeeCustomized.i18n) ||
                              _vm.resources.FeeCustomized.name,
                            type: "number",
                            "icon-pack": "feather",
                            icon: "icon-dollar-sign"
                          },
                          on: { change: _vm.sumValues },
                          model: {
                            value: _vm.feeCustomized,
                            callback: function($$v) {
                              _vm.feeCustomized = $$v
                            },
                            expression: "feeCustomized"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            label:
                              _vm.$t(_vm.resources.TotalValue.i18n) ||
                              _vm.resources.TotalValue.name,
                            value: _vm.totalDisplay,
                            "icon-pack": "feather",
                            icon: "icon-dollar-sign",
                            readonly: ""
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "vx-card",
                {
                  staticClass: "mt-4",
                  attrs: {
                    title:
                      _vm.$t(_vm.resources.ContactFormTitle.i18n) ||
                      _vm.resources.ContactFormTitle.name
                  }
                },
                [
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|min:3|alpha_spaces",
                              expression: "'required|min:3|alpha_spaces'"
                            }
                          ],
                          staticClass: "w-full",
                          attrs: {
                            name: "emergencyName",
                            label:
                              _vm.$t(_vm.resources.ContactName.i18n) ||
                              _vm.resources.ContactName.name,
                            "icon-pack": "feather",
                            icon: "icon-user",
                            maxlength: "100"
                          },
                          model: {
                            value: _vm.emergencyName,
                            callback: function($$v) {
                              _vm.emergencyName = $$v
                            },
                            expression: "emergencyName"
                          }
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(
                            _vm._s(_vm.errors.first("form-2.emergencyName"))
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|email",
                              expression: "'required|email'"
                            }
                          ],
                          staticClass: "w-full",
                          attrs: {
                            name: "emailVoucher",
                            label:
                              _vm.$t(_vm.resources.Email.i18n) ||
                              _vm.resources.Email.name,
                            "icon-pack": "feather",
                            icon: "icon-mail",
                            maxlength: "150"
                          },
                          model: {
                            value: _vm.emailVoucher,
                            callback: function($$v) {
                              _vm.emailVoucher = $$v
                            },
                            expression: "emailVoucher"
                          }
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(
                            _vm._s(_vm.errors.first("form-2.emailVoucher"))
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/6 w-full mb-2" },
                      [
                        _c("label", { staticClass: "vs-input--label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.TelephoneType.i18n) ||
                                _vm.resources.TelephoneType.name
                            )
                          )
                        ]),
                        _c("v-select", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          attrs: {
                            name: "phoneType",
                            placeholder:
                              _vm.$t(_vm.resources.Select.i18n) ||
                              _vm.resources.Select.name,
                            clearable: false,
                            options: _vm.phoneTypes,
                            reduce: function(item) {
                              return item.id
                            },
                            label: "name"
                          },
                          model: {
                            value: _vm.emergencyPhoneType,
                            callback: function($$v) {
                              _vm.emergencyPhoneType = $$v
                            },
                            expression: "emergencyPhoneType"
                          }
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(_vm._s(_vm.errors.first("form-2.phoneType")))
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/6 w-full mb-2" },
                      [
                        _c("label", { staticClass: "vs-input--label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.Indicative.i18n) ||
                                _vm.resources.Indicative.name
                            )
                          )
                        ]),
                        _c("v-select", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          attrs: {
                            name: "emergencyCountryCode",
                            placeholder:
                              _vm.$t(_vm.resources.Select.i18n) ||
                              _vm.resources.Select.name,
                            clearable: false,
                            options: _vm.countryList,
                            reduce: function(item) {
                              return item.id
                            },
                            label: "name"
                          },
                          model: {
                            value: _vm.emergencyCountryCode,
                            callback: function($$v) {
                              _vm.emergencyCountryCode = $$v
                            },
                            expression: "emergencyCountryCode"
                          }
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(
                            _vm._s(
                              _vm.errors.first("form-2.emergencyCountryCode")
                            )
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/6 w-full mb-2" },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|min:8",
                              expression: "'required|min:8'"
                            }
                          ],
                          staticClass: "w-full",
                          attrs: {
                            name: "emergencyPhone",
                            label:
                              _vm.$t(_vm.resources.Number.i18n) ||
                              _vm.resources.Number.name,
                            "icon-pack": "feather",
                            icon: "icon-hash",
                            maxlength: "20"
                          },
                          model: {
                            value: _vm.emergencyPhone,
                            callback: function($$v) {
                              _vm.emergencyPhone = $$v
                            },
                            expression: "emergencyPhone"
                          }
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(
                            _vm._s(_vm.errors.first("form-2.emergencyPhone"))
                          )
                        ])
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "vx-card",
                { staticClass: "mt-4" },
                [
                  _c(
                    "vs-tabs",
                    [
                      _c(
                        "vs-tab",
                        {
                          attrs: {
                            label:
                              _vm.$t(_vm.resources.Itinerary.i18n) ||
                              _vm.resources.Itinerary.name
                          }
                        },
                        _vm._l(_vm.byReference.itineraries, function(
                          item,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c("vs-divider", [
                                _c("strong", { staticClass: "text-primary" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(_vm.resources.Itinerary.i18n) +
                                          " " +
                                          (index + 1) ||
                                          _vm.resources.Itinerary.name +
                                            " " +
                                            (index + 1)
                                      ) +
                                      "\n              "
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "vx-row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/4 w-full mb-2"
                                  },
                                  [
                                    _c("vs-input", {
                                      staticClass: "w-full",
                                      attrs: {
                                        label:
                                          _vm.$t(_vm.resources.Origin.i18n) ||
                                          _vm.resources.Origin.name,
                                        readonly: ""
                                      },
                                      model: {
                                        value: item.origin,
                                        callback: function($$v) {
                                          _vm.$set(item, "origin", $$v)
                                        },
                                        expression: "item.origin"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/4 w-full mb-2"
                                  },
                                  [
                                    _c("vs-input", {
                                      staticClass: "w-full",
                                      attrs: {
                                        label:
                                          _vm.$t(
                                            _vm.resources.Destination.i18n
                                          ) || _vm.resources.Destination.name,
                                        readonly: ""
                                      },
                                      model: {
                                        value: item.destination,
                                        callback: function($$v) {
                                          _vm.$set(item, "destination", $$v)
                                        },
                                        expression: "item.destination"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/4 w-full mb-2"
                                  },
                                  [
                                    _c("vs-input", {
                                      staticClass: "w-full",
                                      attrs: {
                                        label:
                                          _vm.$t(
                                            _vm.resources.StartDate.i18n
                                          ) || _vm.resources.StartDate.name,
                                        readonly: ""
                                      },
                                      model: {
                                        value: item.departureDate,
                                        callback: function($$v) {
                                          _vm.$set(item, "departureDate", $$v)
                                        },
                                        expression: "item.departureDate"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/4 w-full mb-2"
                                  },
                                  [
                                    _c("vs-input", {
                                      staticClass: "w-full",
                                      attrs: {
                                        label:
                                          _vm.$t(_vm.resources.EndDate.i18n) ||
                                          _vm.resources.EndDate.name,
                                        readonly: ""
                                      },
                                      model: {
                                        value: item.arrivalDate,
                                        callback: function($$v) {
                                          _vm.$set(item, "arrivalDate", $$v)
                                        },
                                        expression: "item.arrivalDate"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/4 w-full mb-2"
                                  },
                                  [
                                    _c("vs-input", {
                                      staticClass: "w-full",
                                      attrs: {
                                        label:
                                          _vm.$t(_vm.resources.Class.i18n) ||
                                          _vm.resources.Class.name,
                                        readonly: ""
                                      },
                                      model: {
                                        value: item.class,
                                        callback: function($$v) {
                                          _vm.$set(item, "class", $$v)
                                        },
                                        expression: "item.class"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/4 w-full mb-2"
                                  },
                                  [
                                    _c("vs-input", {
                                      staticClass: "w-full",
                                      attrs: {
                                        label:
                                          _vm.$t(_vm.resources.Airline.i18n) ||
                                          _vm.resources.Airline.name,
                                        readonly: ""
                                      },
                                      model: {
                                        value: item.airlineCode,
                                        callback: function($$v) {
                                          _vm.$set(item, "airlineCode", $$v)
                                        },
                                        expression: "item.airlineCode"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/4 w-full mb-2"
                                  },
                                  [
                                    _c("vs-input", {
                                      staticClass: "w-full",
                                      attrs: {
                                        label:
                                          _vm.$t(
                                            _vm.resources.FlightNumber.i18n
                                          ) || _vm.resources.FlightNumber.name,
                                        readonly: ""
                                      },
                                      model: {
                                        value: item.flightNumber,
                                        callback: function($$v) {
                                          _vm.$set(item, "flightNumber", $$v)
                                        },
                                        expression: "item.flightNumber"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c(
                        "vs-tab",
                        {
                          attrs: {
                            label:
                              _vm.$t(_vm.resources.Passengers.i18n) ||
                              _vm.resources.Passengers.name
                          }
                        },
                        _vm._l(_vm.byReference.passengers, function(
                          item,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c("vs-divider", [
                                _c("strong", { staticClass: "text-primary" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(_vm.resources.Passenger.i18n) +
                                          " " +
                                          (index + 1) ||
                                          _vm.resources.Passenger.name +
                                            " " +
                                            (index + 1)
                                      )
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "vx-row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/4 w-full mb-2"
                                  },
                                  [
                                    _c("vs-input", {
                                      staticClass: "w-full",
                                      attrs: {
                                        label:
                                          _vm.$t(_vm.resources.Name.i18n) ||
                                          _vm.resources.Name.name,
                                        readonly: ""
                                      },
                                      model: {
                                        value: item.name,
                                        callback: function($$v) {
                                          _vm.$set(item, "name", $$v)
                                        },
                                        expression: "item.name"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/4 w-full mb-2"
                                  },
                                  [
                                    _c("vs-input", {
                                      staticClass: "w-full",
                                      attrs: {
                                        label:
                                          _vm.$t(_vm.resources.LastName.i18n) ||
                                          _vm.resources.LastName.name,
                                        readonly: ""
                                      },
                                      model: {
                                        value: item.lastName,
                                        callback: function($$v) {
                                          _vm.$set(item, "lastName", $$v)
                                        },
                                        expression: "item.lastName"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/4 w-full mb-2"
                                  },
                                  [
                                    _c("vs-input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required|email",
                                          expression: "'required|email'"
                                        }
                                      ],
                                      staticClass: "w-full",
                                      attrs: {
                                        name: "passengerMail" + (index + 1),
                                        label:
                                          _vm.$t(_vm.resources.Email.i18n) ||
                                          _vm.resources.Email.name,
                                        maxlength: "150"
                                      },
                                      model: {
                                        value: item.email,
                                        callback: function($$v) {
                                          _vm.$set(item, "email", $$v)
                                        },
                                        expression: "item.email"
                                      }
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "text-danger text-sm" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.errors.first(
                                              "form-2.passengerMail" +
                                                (index + 1)
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/4 w-full mb-2"
                                  },
                                  [
                                    _c("vs-input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required|min:8",
                                          expression: "'required|min:8'"
                                        }
                                      ],
                                      staticClass: "w-full",
                                      attrs: {
                                        name: "passengerPhone" + (index + 1),
                                        label:
                                          _vm.$t(_vm.resources.Phone.i18n) ||
                                          _vm.resources.Phone.name,
                                        "icon-pack": "feather",
                                        icon: "icon-phone",
                                        maxlength: "20"
                                      },
                                      model: {
                                        value: item.phone,
                                        callback: function($$v) {
                                          _vm.$set(item, "phone", $$v)
                                        },
                                        expression: "item.phone"
                                      }
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "text-danger text-sm" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.errors.first(
                                              "form-2.passengerPhone" +
                                                (index + 1)
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/4 w-full mb-2"
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "vs-input--label" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.PassengerType.i18n
                                            ) ||
                                              _vm.resources.PassengerType.name
                                          )
                                        )
                                      ]
                                    ),
                                    _c("v-select", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'"
                                        }
                                      ],
                                      attrs: {
                                        name: "passengerType" + (index + 1),
                                        clearable: false,
                                        options: _vm.passengerType,
                                        reduce: function(x) {
                                          return x.id
                                        },
                                        label: "name"
                                      },
                                      model: {
                                        value: item.typeId,
                                        callback: function($$v) {
                                          _vm.$set(item, "typeId", $$v)
                                        },
                                        expression: "item.typeId"
                                      }
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "text-danger text-sm" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.errors.first(
                                              "form-2.passengerType" +
                                                (index + 1)
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/4 w-full mb-2"
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "vs-input--label" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.DocumentType.i18n
                                            ) || _vm.resources.DocumentType.name
                                          )
                                        )
                                      ]
                                    ),
                                    _c("v-select", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'"
                                        }
                                      ],
                                      attrs: {
                                        name:
                                          "passengerDocumentType" + (index + 1),
                                        placeholder:
                                          _vm.$t(_vm.resources.Select.i18n) ||
                                          _vm.resources.Select.name,
                                        clearable: false,
                                        options: _vm.documentTypeOption,
                                        reduce: function(x) {
                                          return x.id
                                        },
                                        label: "name"
                                      },
                                      model: {
                                        value: item.documentTypeId,
                                        callback: function($$v) {
                                          _vm.$set(item, "documentTypeId", $$v)
                                        },
                                        expression: "item.documentTypeId"
                                      }
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "text-danger text-sm" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.errors.first(
                                              "form-2.passengerDocumentType" +
                                                (index + 1)
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/4 w-full mb-2"
                                  },
                                  [
                                    _c("vs-input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required|alpha_num",
                                          expression: "'required|alpha_num'"
                                        }
                                      ],
                                      staticClass: "w-full",
                                      attrs: {
                                        name:
                                          "passengerDocumentNumber" +
                                          (index + 1),
                                        label:
                                          _vm.$t(
                                            _vm.resources.DocumentNumber.i18n
                                          ) ||
                                          _vm.resources.DocumentNumber.name,
                                        "icon-pack": "feather",
                                        icon: "icon-hash",
                                        maxlength: "25"
                                      },
                                      model: {
                                        value: item.document,
                                        callback: function($$v) {
                                          _vm.$set(item, "document", $$v)
                                        },
                                        expression: "item.document"
                                      }
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "text-danger text-sm" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.errors.first(
                                              "form-2.passengerDocumentNumber" +
                                                (index + 1)
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/4 w-full mb-2"
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "vs-input--label" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.BirthDate.i18n
                                            ) || _vm.resources.BirthDate.name
                                          )
                                        )
                                      ]
                                    ),
                                    _c("datepicker", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'"
                                        }
                                      ],
                                      attrs: {
                                        name:
                                          "passengerBirthDate" + (index + 1),
                                        format: "d MMMM yyyy",
                                        language: _vm.languages[_vm.language]
                                      },
                                      model: {
                                        value: item.birthDate,
                                        callback: function($$v) {
                                          _vm.$set(item, "birthDate", $$v)
                                        },
                                        expression: "item.birthDate"
                                      }
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "text-danger text-sm" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.errors.first(
                                              "form-2.passengerBirthDate" +
                                                (index + 1)
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/4 w-full mb-2"
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "vs-input--label" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources
                                                .ExpirationDatePassport.i18n
                                            ) ||
                                              _vm.resources
                                                .ExpirationDatePassport.name
                                          )
                                        )
                                      ]
                                    ),
                                    _c("datepicker", {
                                      attrs: {
                                        format: "d MMMM yyyy",
                                        language: _vm.languages[_vm.language]
                                      },
                                      model: {
                                        value: item.expirationDate,
                                        callback: function($$v) {
                                          _vm.$set(item, "expirationDate", $$v)
                                        },
                                        expression: "item.expirationDate"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm.country == "USA"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "vx-col md:w-1/4 w-full mb-2"
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "vs-input--label" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.resources.Country.i18n
                                                ) || _vm.resources.Country.name
                                              )
                                            )
                                          ]
                                        ),
                                        _c("v-select", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'"
                                            }
                                          ],
                                          attrs: {
                                            name:
                                              "passengerNationality" +
                                              (index + 1),
                                            placeholder:
                                              _vm.$t(
                                                _vm.resources.Select.i18n
                                              ) || _vm.resources.Select.name,
                                            clearable: false,
                                            options: _vm.countryPassenger,
                                            reduce: function(x) {
                                              return x.id
                                            },
                                            label: "name"
                                          },
                                          model: {
                                            value: item.nationality,
                                            callback: function($$v) {
                                              _vm.$set(item, "nationality", $$v)
                                            },
                                            expression: "item.nationality"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "text-danger text-sm"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.errors.first(
                                                  "form-2.passengerNationality" +
                                                    (index + 1)
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ])
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "vx-col mb-2 mt-5",
                  staticStyle: { "text-align": "right" }
                },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: { color: "primary", type: "filled" },
                      on: { click: _vm.generateBooking }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t(_vm.resources.Save.i18n) ||
                              _vm.resources.Save.name
                          ) +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }